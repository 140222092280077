<template>
  <div class="container">
    <section class="not-found">
      <b-icon class="text-muted" icon="exclamation-circle-fill" size="2x" style="width: 120px; height: 120px;"></b-icon>
      <h1 class=" my-4 font-weight-bold">404 - No encontrado !</h1>
      <p class="text-muted">La página que estás buscando no existe.</p>

      <div class="w-100 py-4"></div>
      <button @click="goBack" class="btn btn-link px-5">VOLVER ATRAS</button>
      <button @click="$emit('gohome')" class="btn btn-primary text-white px-5">IR AL INICIO</button>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {

      this.$router.go(-1)
    }
  }
}
</script>

<style>
.not-found {
  width: 100%;
  min-height: 80vh;
  padding: 50px;
  padding-top: 120px;
  text-transform: uppercase;
  text-align: center;
  background: white url('/images/notfound.svg') center center no-repeat;
  background-size: contain;
}
</style>